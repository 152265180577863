<template>
<div>加载中</div>
</template>

<script>
import { request, requestForm } from '@/assets/js/http.js'
export default {
  name: 'RedirectAction',
  data: function () {
    return {
      loginInfo: {}
    }
  },
  created () {
    request('/api/system/user/getUserInfoAndMenuMessage', 'GET', {}).then((response) => {
      if (response.code === '200') {
        this.loginInfo = response.data
        this.goPage()
      } else {
        location.href = '/index'
        // this.$router.push('/index')
      }
    })
  },
  methods: {
    goPage () {
      const usetType = this.loginInfo.userType
      if (usetType || usetType === 0) {
        const id = this.$route.query.id
        const view = this.$route.query.view
        if (view === 'BarCode') {
          if (usetType === 0) {
            // this.$router.push({ name: 'View', params: { frameName: 'Approve', viewName: 'BarCodeApproval' }, query: { id: id } })
            location.href = '/Approve/BarCodeApproval?id=' + id
          }
          if (usetType === 1) {
            // this.$router.push({ name: 'View', params: { frameName: 'Approve', viewName: 'BarCodeApproval' }, query: { id: id } })
            location.href = '/Approve/BarCodeApproval?id=' + id
          }
        }
        if (view === 'bid') {
          if (usetType === 0) {
            request('/api/bid/getStatusByBidId/' + id, 'post', {}).then((response) => {
              if (response.code === '200') {
                if (response.data === null || response.data.status !== 0) {
                  location.href = `/Invitation/BidFormAll?id=${id}`
                } else {
                  // this.$router.push({ name: 'View', params: { frameName: 'Invitation', viewName: 'BidFormAll' }, query: { id: id } })
                  location.href = '/Invitation/ExpertMark'
                }
              } else {
                // this.$router.push('/index')
                location.href = '/index'
              }
            })
          }
          if (usetType === 1) {
            requestForm('/api/bid/getStatusByBidAndSupplierId', 'post', { bidId: id, supplierId: sessionStorage.getItem('enterpriseCode') }).then((Response) => {
              if (Response.code === '200') {
                if (Response.data === 1) {
                  // this.$router.push({ name: 'View', params: { frameName: 'SalesLeads', viewName: 'BidDetails' }, query: { id: id, status: Response.data } })
                  location.href = `/SalesLeads/BidDetails?id=${id}&status=${Response.data}`
                } else {
                  // this.$router.push({ name: 'View', params: { frameName: 'SalesLeads', viewName: 'MyBidRating' }, query: { id: id, status: Response.data } })
                  location.href = `/SalesLeads/MyBidRating?id=${id}&status=${Response.data}`
                }
              } else {
                // this.$router.push('/index')
                location.href = '/index'
              }
            })
          }
        }
        if (view === 'CheckDifferences') {
          if (usetType === 0) {
            // this.$router.push({ name: 'View', params: { frameName: 'FinancialManagement', viewName: 'StatementSeeDetail' }, query: { id: id } })
            location.href = `/FinancialManagement/StatementSeeDetail?id=${id}`
          }
          if (usetType === 1) {
            // this.$router.push({ name: 'View', params: { frameName: 'FinancialManagement', viewName: 'SupplierStatementDetail' }, query: { id: id } })
            location.href = `/FinancialManagement/SupplierStatementDetail?id=${id}`
          }
        }
        if (view === 'ContractAward') {
          if (usetType === 0) {
            // this.$router.push({ name: 'View', params: { frameName: 'ContractOrder', viewName: 'DraftaContract' }, query: { id: id } })
            location.href = `/ContractOrder/DraftaContract?id=${id}`
          }
          if (usetType === 1) {
            // this.$router.push({ name: 'View', params: { frameName: 'ContractOrder', viewName: 'ContractSign' }, query: { id: id } })
            location.href = `/ContractOrder/ContractSign?id=${id}`
          }
        }
        if (view === 'issueinquiry') {
          if (usetType === 0) {
            // this.$router.push({ name: 'View', params: { frameName: 'Invitation', viewName: 'IssueDetails' }, query: { id: id } })
            location.href = `/Invitation/IssueDetails?id=${id}`
          }
          if (usetType === 1) {
            request('/api/inquiry/getIsQuote', 'get', { inquiryId: id, supplierId: sessionStorage.getItem('enterpriseCode') }).then((Response) => {
              if (Response.code === '200') {
                // this.$router.push({ name: 'View', params: { frameName: 'SalesLeads', viewName: 'InquiryDetails' }, query: { id: id, isQuote: Response.data } })
                location.href = `/SalesLeads/InquiryDetails?id=${id}&isQuote=${Response.data}`
              } else {
                // this.$router.push('/index')
                location.href = '/index'
              }
            })
          }
        }
        if (view === 'materialchange') {
          request(`/api/supplier/material/getById/${id}/1`, 'get').then((Response) => {
            if (Response.code === '200') {
              // this.$router.push({ name: 'View', params: { frameName: 'Approve', viewName: 'MaterialApproval' }, query: { id: id, state: Response.data.approvalStatus } })
              location.href = `/Approve/MaterialApproval?id=${id}&state=${Response.data.approvalStatus}`
            } else {
              // this.$router.push('/index')
              location.href = '/index'
            }
          })
        }
        if (view === 'PaymentApproval') {
          if (usetType === 0) {
            request('/api/reconciliation/pay/getByPayOrderId/' + id, 'get').then((res) => {
              if (res.code === '200') {
                if (res.data.type === 1) {
                  // this.$router.push({ name: 'View', params: { frameName: 'FinancialManagement', viewName: 'PaymentFindDetail' }, query: { id: id } })
                  location.href = `/FinancialManagement/PaymentFindDetail?id=${id}`
                } else if (res.data.type === 2) {
                  // this.$router.push({ name: 'View', params: { frameName: 'FinancialManagement', viewName: 'AdvancePaymentFindDetail' }, query: { id: id } })
                  location.href = `/FinancialManagement/AdvancePaymentFindDetail?id=${id}`
                } else {
                  // this.$router.push('/index')
                  location.href = '/index'
                }
              } else {
                // this.$router.push('/index')
                location.href = '/index'
              }
            })
          }
        }
        if (view === 'supplierregistration') {
          if (usetType === 0) {
            // this.$router.push({ name: 'View', params: { frameName: 'MainData', viewName: 'SupplierRegistration' }, query: { id: id } })
            location.href = `/MainData/SupplierRegistration?id=${id}`
          }
          if (usetType === 1) {
            // this.$router.push({ name: 'View', params: { frameName: 'myCompany', viewName: 'CompanyMessage' } })
            location.href = '/myCompany/CompanyMessage'
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
